<template>
    <div>
       <!-- Tarjeta Ventas -->
        <v-card
            v-if="!Mensaje.error"
                class="mx-auto mt-6"
                max-width="260"
                id="CuentaCopiar"
            >
            <v-card-text >
                
                <h2 class="red--text text-center">Retiro con código QR</h2>
                <p class="red--text text-center">Se ha completado exitosamente!</p>
                
                <div class="text--primary mt-5 mb-2">
                    
                    ID: <strong>{{Mensaje.id_transaccion}}</strong><br>
                    Monto: <strong>{{Item.valor | moneda}}</strong><br>
                    Producto: <strong>{{MedioDePago}}</strong><br>
                    Destino: <strong>QR Payment</strong><br>
                    Aprobación: <strong>{{Item.codigo_aprobacion}}</strong><br>
                    Fecha: <strong>{{ FechaHoraActual }} </strong>
                </div>

                <v-divider class="d-print-none"></v-divider>
                <br>
                <p class="mt-2"> Te deseamos lo mejor!</p>
                <p class="mt-n5">dilibit.com</p>

            </v-card-text>

            <v-card-actions class="mt-n6 d-print-none">

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="green"
                          icon
                          text
                          v-bind="attrs"
                          v-on="on"
                          @click="LLenarCelularWS"
                        >
                        <v-icon>mdi-whatsapp</v-icon>
                        </v-btn>
                    </template>
                  <span>Enviar por Whatsapp</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          icon
                          text
                          v-bind="attrs"
                          v-on="on"
                          @click="Imprimir()"
                        >
                        <v-icon>mdi-printer</v-icon>
                        </v-btn>
                    </template>
                  <span>Imprimir</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="warning"
                          icon
                          text
                          v-bind="attrs"
                          v-on="on"
                          @click="Copiar()"
                        >
                        <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                    </template>
                  <span>Copiar Información</span>
                </v-tooltip>

                <v-spacer></v-spacer>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="error"
                          icon
                          text
                          v-bind="attrs"
                          v-on="on"
                          @click="FinalizarRetiro()"
                        >
                        <v-icon>mdi-reply</v-icon>
                        </v-btn>
                    </template>
                  <span>Finalizar</span>
                </v-tooltip>
                
            </v-card-actions>
        </v-card>
        <!-- Tarjeta Ventas -->

      <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          v-else
      >
        <v-card>
        <v-card-title>
          
        </v-card-title>
        
        <v-card-text>
          <v-container>
            
              
              <v-list three-line>
                <v-list-item
                    ripple
                >
                    <v-row>
                        <v-col
                            cols="12"
                            sm="12"
                            md="5"
                        >
                            <v-img
                            class="mx-auto"
                            src="@/assets/tramites/retirosqr.png"
                            width="350px"
                            align="center"
                            justify="center"
                            ></v-img>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="12"
                            md="5"
                        >
                            <v-list-item-content>
                                <h1 class="text-center">Retiros QR</h1>
                                <h3 class="text-center">Retiros Código QR</h3>
                                <h3 class="text-center red--text" v-if="Accion=='Nuevo'">NUEVO</h3>
                                <h3 class="text-center red--text" v-else>VER</h3>
                            </v-list-item-content>
                        </v-col>
                    </v-row>
                </v-list-item>
               </v-list>
              
              <v-divider></v-divider>

              <v-row class="mt-2">

                <v-col
                cols="12"
                v-if="Item.medio_pago == 3 && Accion == 'Nuevo'"
              >
              <v-alert type="error">
                <h3 class="text-center">¡ ALERTA !</h3>
                <h3 class="mt-2 text-center">Exclusivo cuentas de ahorro y corriente Bancolombia</h3>
                <h4 class="mt-2 text-center">*** No disponible para cuentas ahorro a la mano ***</h4>
              </v-alert>
              </v-col>

               <v-col
                cols="12"
                sm="6"
              >
               <v-select
                  v-model="Item.medio_pago"
                  :items="MediosPago"
                  :loading="Item.medio_pago == '' ? true : false"
                  item-text="titulo"
                  item-value="id"
                  :menu-props="{ maxHeight: '250'}"
                  label="Medio de pago"
                  hint="Seleccione el medio de pago *"
                  persistent-hint
                  :rules="SelectRules"
                  :clearable="Accion == 'Nuevo'"
                  :readonly="Accion == 'Editar'"
                  required
                ></v-select>
              </v-col>

              

              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-if="Item.medio_pago!= '' || Accion == 'Editar'"
                  type="number"
                  :loading="Item.cuenta == '' ? true : false"
                  counter
                  v-model="Item.cuenta"
                  :label="Item.medio_pago != 3 ? 'Número Celular *' : 'Numero de Cuenta *'"
                  :hint="Item.medio_pago != 3 ? 'Celular que realiza el pago QR' : 'Cuenta que realiza el pago QR'"
                  persistent-hint
                  :rules="CuentaRules"
                  :clearable="Accion == 'Nuevo'"
                  :readonly="Accion == 'Editar'"
                  required
                ></v-text-field>
              </v-col>

               <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-if="Item.cuenta.length >= 10 || Accion == 'Editar'"
                  :loading="Item.valor == '' ? true : false"
                  type="number"
                  counter
                  v-model="Item.valor"
                  prefix="$"
                  label="Ingrese valor del pago *"
                  hint="Ingrese el valor sin puntos ni símbolos"
                  persistent-hint
                  :rules="NameRules"
                  :clearable="Accion == 'Nuevo'"
                  :readonly="Accion == 'Editar'"
                  required
                ></v-text-field>
              </v-col>

               <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-if="Item.valor.length >= 3 || Accion == 'Editar'"
                  :loading="Item.codigo_aprobacion == '' ? true : false"
                  type="number"
                  counter
                  v-model="Item.codigo_aprobacion"
                  label="Código Aprobación *"
                  hint="Referencia que aprueba el pago QR"
                  persistent-hint
                  :rules="NameRules"
                  :clearable="Accion == 'Nuevo'"
                  :readonly="Accion == 'Editar'"
                  required
                ></v-text-field>
              </v-col>

            </v-row>
          </v-container>

          <h3 v-if="valid">* indica campo requerido</h3><br>
          <h3 v-if="!valid" class="red--text">Por favor llene todos los campos requeridos *</h3>

        </v-card-text>

        <v-card-actions >
          <v-spacer></v-spacer>

          <v-btn color="error" @click="Ocultar()">Cerrar</v-btn>

          <v-btn
            color="warning"
            @click="reset()"
            v-if="Accion == 'Nuevo'"
          >
            Borrar Campos
          </v-btn>

          <v-btn
            v-if="Item.codigo_aprobacion.length >= 3 && Accion == 'Nuevo'"
            :disabled="!valid"
            @click="validate"
            color="primary"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
    
        <!--Popup Disponible-->
        <v-dialog persistent v-model="DialogAceptar" max-width="650px">
          <v-card>

            <v-toolbar
              color="success"
              dark
            >
            <v-spacer></v-spacer>
              Retiros QR
            <v-spacer></v-spacer>
            </v-toolbar>

            <v-container>
              <v-row>
                <v-col
                cols="12"
                sm="6"
              >
               <v-select
                  v-model="Item.medio_pago"
                  :items="MediosPago"
                  item-text="titulo"
                  item-value="id"
                  label="Medio de pago"
                  readonly
                ></v-select>
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  counter
                  v-model="Item.cuenta"
                  :label="Item.medio_pago != 3 ? 'Número Celular *' : 'Numero de Cuenta *'"
                  readonly
                ></v-text-field>
              </v-col>

               <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  counter
                  v-model="Item.valor"
                  prefix="$"
                  label="Valor del pago *"
                  readonly
                ></v-text-field>
              </v-col>

               <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  counter
                  v-model="Item.codigo_aprobacion"
                  label="Código Aprobación *"
                  hint="Referencia que aprueba el pago QR"
                  persistent-hint
                  readonly
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
              >
                <v-text-field
                  type="password"
                  v-model="ItemEnviar.pin"
                  :loading="ItemEnviar.pin == '' ? true : false"
                  label="Ingrese PIN *"
                  hint="Indique el pin de venta."
                  persistent-hint
                  :rules="pinRules"
                  :counter="4"
                  required
                  clearable
                ></v-text-field>
              </v-col>
              </v-row>
            </v-container>

            <v-divider></v-divider>

            <v-card-actions class="mt-2">
              <v-spacer></v-spacer>
              <v-btn color="error" @click="DialogAceptar = false">Cerrar</v-btn>
              <v-btn v-if="ItemEnviar.pin.length == 4" color="primary" @click="Guardar()">Retirar</v-btn>
              <v-btn v-else disabled color="default">Retirar</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

    <v-dialog
      v-model="dialogCelularWS"
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h6">
         Ingresa Número de celular
        </v-card-title>

        <v-card-text>
          Se enviarán datos al whatsapp
          <v-text-field
            v-model="numCelWS"
            label="Celular *"
            type="number"
            class="mt-2"
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="dialogCelularWS = false"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="WS()"
          >
            Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations} from 'vuex'

export default ({
  props:['Accion','Item','NuevoActivo'],

  watch:{
      Alerta(value){
        if(value.error == false){
          this.Ocultar()
        }
      },
  },

   created(){
    
   },

   mounted() {
     
   },

  data: () => ({
    var: null,
    DialogAceptar: false,
    dialogCelularWS: false,
    numCelWS: '',
    ItemEnviar: {
          id: 0, 
          medio_pago: '', 
          cuenta: '',
          valor: '',
          codigo_aprobacion: '', 
          pin: ''
    },

    valid: true,

    ReglasDoc: [
        v => !v || v.size < 2000000 || 'El tamaño del docuento no puede superar las 2 MB!',
      ],

      NameRules: [
        v => !!v || 'Campo Requerido',
        v => (v && v.length >= 3) || 'Campo debe ser mayor a 3 caracteres.',
      ],

      CuentaRules: [
        v => !!v || 'Campo Requerido',
        v => (v && v.length >= 10) || 'Campo debe ser mayor a 9 caracteres.',
      ],

      EmailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail Debe ser válido',
      ],

      SelectRules:[
        v => !!v || 'Campo Requerido',
      ],
      pinRules:[
        v => v.length == 4 || 'PIN debe tener 4 digitos',
      ],

  }),

  methods: {
    
     ...mapActions(['ConsultarPuntoDeVenta']),
     ...mapActions('billeteras',['ConsultarRetirosQR','NuevoRetirosQR']),
     ...mapMutations('billeteras',['LLenarMensaje']),

      validate () {
        this.$refs.form.validate()

        if(this.valid){
          this.DialogAceptar=true;
        }
      },

      reset () {
        this.$refs.form.reset()
        this.$refs.form.resetValidation()
        this.Item.id= 0
        this.Item.medio_pago= ''
        this.Item.cuenta= ''
        this.Item.valor= ''
        this.Item.codigo_aprobacion= ''
        this.Item.pin= ''
    },

    async Guardar (){

        this.ItemEnviar.id = this.Item.id
        this.ItemEnviar.medio_pago = this.Item.medio_pago
        this.ItemEnviar.cuenta = this.Item.cuenta
        this.ItemEnviar.valor = this.Item.valor
        this.ItemEnviar.codigo_aprobacion = this.Item.codigo_aprobacion
        this.ItemEnviar.pin = this.ItemEnviar.pin
        await this.NuevoRetirosQR(this.ItemEnviar)
        await this.ConsultarRetirosQR();
        await this.ConsultarPuntoDeVenta();
        this.DialogAceptar = false
    },

    FinalizarRetiro(){
      var Mensaje= {status: 500, mje: '', error: true, id_transaccion:0}
      this.LLenarMensaje(Mensaje);
      this.Ocultar();
    },

    Ocultar(){
        this.$emit('NuevoActivo', false)
    },

    /* Copiar */
			Copiar: function(){
				var elemento = 'CuentaCopiar';
				var codigoACopiar = document.getElementById(elemento);
				var seleccion = document.createRange();
				seleccion.selectNodeContents(codigoACopiar);
				window.getSelection().removeAllRanges();
				window.getSelection().addRange(seleccion);
				var res = document.execCommand('copy');
				window.getSelection().removeRange(seleccion);
			},
			/* Fin Copiar */

      LLenarCelularWS: function(){
        if(this.Item.medio_pago != 3){
          this.numCelWS = this.Item.cuenta
        }
        this.dialogCelularWS = true;
      },

      WS: function(){
          this.dialogCelularWS= false;
          var saltolinea = '%0A'
            var mensajews = 'Retiro con código QR'+saltolinea
            mensajews += "Se ha completado exitosamente!"+saltolinea
            mensajews += "ID: "+this.Mensaje.id_transaccion+saltolinea
            mensajews += "Monto: $"+this.Item.valor+saltolinea
            mensajews += "Producto: "+this.MedioDePago+saltolinea
            mensajews += "Destino: QR Payment"+saltolinea
            mensajews += "Aprobación: "+this.Item.codigo_aprobacion+saltolinea
            mensajews += "Fecha: "+this.FechaHoraActual+saltolinea+saltolinea
            mensajews += "Te deseamos lo mejor!"+saltolinea
            mensajews += "dilibit.com"
            var url = 'https://api.whatsapp.com/send?phone=57'+this.numCelWS+'&text='+mensajews;
            window.open(url);
      },

			/* Funcion Para Imprimir */
			Imprimir: function() {
				var ficha = document.getElementById('CuentaCopiar');
				var ventimp = window.open(' ', 'popimpr');
				ventimp.document.write( ficha.innerHTML );
				ventimp.document.close();
				ventimp.print( );
				ventimp.close();
			  },
			/* Fin Funcion Para Imprimir */
  },

  computed:{
    ...mapState([]),
    ...mapGetters('billeteras',['MediosPago','Mensaje']),

    FechaHoraActual(){
      var hoy = new Date();
      var fecha = hoy.getDate() + '-' + ( hoy.getMonth() + 1 ) + '-' + hoy.getFullYear();
      var hora = hoy.getHours() + ':' + hoy.getMinutes() + ':' + hoy.getSeconds();
      return fecha+' '+hora; 
    },

    MedioDePago() {
        var medios = this.MediosPago
        for(var i in medios){
          if(medios[i].id == this.Item.medio_pago){
            return medios[i].titulo.slice(3);
          }
        }
      return null;
    },

  },

  filters: {
    mayuscula: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    },

    moneda: function (value) {
     return '$'+value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    

  },

})
</script>