<template>
  <div class="eps">
    <tabla titulo="Retiros QR" 
          :headers = "headers"
          :arreglo = "RetirosQR.items"
            />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import tabla from '@/components/tramites/billetera/index-retirosqr';

export default {
  name: 'RetirosQR',

   components: {
    tabla
  },
  
  created(){
    this.ConsultarRetirosQR()
  },

  data: () =>{
    return{
    headers: [
        {
          text: 'ID',
          align: 'start',
          value: 'id',
        },
        { text: 'FECHA', value: 'fecha_format' },
        { text: 'MEDIO DE PAGO', value: 'medio_pago_format' },
        { text: 'CUENTA', value: 'cuenta' },
        { text: 'VALOR', value: 'valor_format' },
        { text: 'CÓDIGO APROBACIÓN', value: 'codigo_aprobacion' },
        { text: 'ACCIONES', value: 'actions', sortable: false },
      ],

    }
  },

  methods: {
    ...mapActions('billeteras',['ConsultarRetirosQR']),
  },

  computed:{
      ...mapGetters('billeteras',['RetirosQR'])
  }
}
</script>
